import Modal from 'react-bootstrap/Modal';
import { Col, Row, Spinner, Stack } from "react-bootstrap";
import { useContext, useState, useRef } from 'react';
import { DrinkUpServiceApiContext } from '../../DrinkUpServiceApiContext';
import { UserContext } from '../../hooks/useUser';
import { IndependentBottlerRow } from '../UserPage/UserProfileModal';

export default function AdminUserModal({ closeModal, show, user, rums}) {    

    const { authToken } = useContext(UserContext);
    const { drinkUpService } = useContext(DrinkUpServiceApiContext);

    const [userRums, setUserRums] = useState([]);

    const isInitialLoad = useRef(true);
    const isDoneLoading = useRef(false);
    const currentUserId = useRef(0);
    
    if(!user) {
        return (
            <></>
        )
    }

    if(currentUserId.current !== user.id) {
        currentUserId.current = user.id;
        isInitialLoad.current = true;
        isDoneLoading.current = false;
    }

    const loadRequests = () => {
        
        console.log("loading rum requests");

        drinkUpService.get(`/get_user_rums?userId=${user.user}`, {
            headers: {
                'Authorization': `Token ${authToken}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            console.log("num rum requests: " + response.data.length);
            console.log("requests: " + JSON.stringify(response.data));
            isDoneLoading.current = true;

            const userRumsArray = response.data;
            const userRumsRequested = userRumsArray.filter((request) => !!request.requested_on);
            const sortedRequests = userRumsRequested.sort(function(a,b){
                return Date.parse(b.approved_on) - Date.parse(a.approved_on);
              });

            setUserRums(sortedRequests);
        })
        .catch(err => {
            console.log(err);
        });
    }

    function formattedDateTime(dateString) {
        const date = new Date(Date.parse(dateString));

        var ampm = "AM";

        var hours = date.getHours();
        if(hours > 12) {
            hours = hours - 12;
            ampm = "PM";
        } else if(hours === 0) {
            hours = 12;
        } else if(hours === 12) {
            ampm = "PM";
        }

        return date.toDateString() + ", " + hours + ":" + String(date.getMinutes()).padStart(2, '0') + " " + ampm;
    }

    if (isInitialLoad.current) {
        isInitialLoad.current = false;
        loadRequests();
    }

    if (!isDoneLoading.current) {
        return (
            <>
            <Modal show={show} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{user.first_name} {user.last_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="justify-content-center align-items-center align-self-center mt-4">
                        <Col>
                            <div className="d-flex align-items-center align-self-center justify-content-center">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            </>
        )
    }

    const numSignedRums = userRums.filter(rum => "approved_on" in rum).length;
    const numAwaitingApproval = userRums.filter(rum => !rum.approved_on && rum.requested_on).length;

    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{user.first_name} {user.last_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack direction="horizontal">
                    <div className="p-2">Total Signed Rums:</div>
                    <div className="p-2 ms-auto">{numSignedRums}</div>
                </Stack>
                <Stack direction="horizontal">
                    <div className="p-2">Rums awaiting approval:</div>
                    <div className="p-2 ms-auto">{numAwaitingApproval}</div>
                </Stack>

                <div className="p-2" style={{borderBottom: "1px solid grey"}}>
                    <label className="fw-bold">INDEPENDENT BOTTLERS</label>
                    <IndependentBottlerRow label={"Blackadder"} rums={userRums} needed={15} filter={(rum) => rum.bevager_name.toLowerCase().includes("blackadder") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Boutique-y"} rums={userRums} needed={18} filter={(rum) => rum.bevager_name.toLowerCase().includes("boutique") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Bristol Classic/Bristol Spirits"} rums={userRums} needed={17} filter={(rum) => rum.bevager_name.toLowerCase().includes("bristol") && !rum.bevager_name.toLowerCase().includes("avery") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Cadenhead"} rums={userRums} needed={14} filter={(rum) => rum.bevager_name.toLowerCase().includes("cadenhead") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Colours of Rum"} rums={userRums} needed={14} filter={(rum) => rum.bevager_name.toLowerCase().includes("colours of rum") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Duncan Taylor"} rums={userRums} needed={8} filter={(rum) => rum.bevager_name.toLowerCase().includes("duncan") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Hamilton"} rums={userRums} needed={15} filter={(rum) => rum.bevager_name.toLowerCase().includes("hamilton") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Holmes Cay"} rums={userRums} needed={24} filter={(rum) => rum.bevager_name.toLowerCase().includes("holmes cay") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Plantation"} rums={userRums} needed={16} filter={(rum) => (rum.bevager_name.toLowerCase().includes("plantation") || rum.bevager_name.toLowerCase().includes("planteray")) && !rum.bevager_name.toLowerCase().includes("grove") && !rum.bevager_name.toLowerCase().includes("myer") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Royal Cane"} rums={userRums} needed={13} filter={(rum) => rum.bevager_name.toLowerCase().includes("royal cane") && "approver" in rum}/>
                    <IndependentBottlerRow label={"S.B.S"} rums={userRums} needed={15} filter={(rum) => rum.bevager_name.includes("SBS") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Samaroli"} rums={userRums} needed={22} filter={(rum) => rum.bevager_name.toLowerCase().includes("samaroli") && "approver" in rum}/>
                    <IndependentBottlerRow label={"Velier"} rums={userRums} needed={42} filter={(rum) => (rum.bevager_name.toLowerCase().includes("velier") || rum.bevager_name.toLowerCase().includes("lm&v")) && "approver" in rum}/>
                </div>

            {userRums.map(function(userRum, i) {

                let requestColor = "approver" in userRum ? "green" : "requested_on" in userRum ? "gray" : "red"    
                let displayString = "";

                if(!!userRum.rejected_on) {
                    let rejectionReason = "No reason given"
                    if(!!userRum.rejection_reason) {
                        rejectionReason = userRum.rejection_reason;
                    }
                    requestColor = "red";
                    displayString = "Rejected By " + userRum.approver + formattedDateTime(userRum.rejected_on) + ". " + rejectionReason;
                } else if(!!userRum.approved_on) {
                    displayString = " Approved by " + userRum.approver + ", " + formattedDateTime(userRum.approved_on);
                } else {
                    displayString = " Requested " + formattedDateTime(userRum.requested_on);
                }           

                return (
                    <div key={i} style={{borderBottom: "1px solid black"}}>
                        <Row>
                            <Col className={userRum.is_immortal ? "immortal" : "regular-rum"}>{userRum.bevager_name}</Col>
                        </Row>
                        <Row>
                            <Col style={{color: requestColor}}>
                                {displayString}
                            </Col>
                        </Row>
                    </div>
                )
            })}
            </Modal.Body>
        </Modal>
        </>
    );
}