import { UserContext } from "../../hooks/useUser";
import { useContext, useRef } from "react";
import { Modal, Button, Stack } from "react-bootstrap";

export function ProfileDataRow(props) {

    const { label, value } = props;

    return (
        <Stack direction="horizontal">
            <div className="p-2">{label}</div>
            <div className="p-2 ms-auto">{value}</div>
        </Stack>
    )
}

export function IndependentBottlerRow(props) {
    const { label, rums, needed, filter} = props;

    const haveAlready = useRef(-1);
    if(haveAlready.current === -1) {
        haveAlready.current = rums.filter(filter).length;
    }    

    return  (
        <ProfileDataRow label={label} value={`${haveAlready.current}/${needed}`} />
    )
}

function UserProfileModal(props) {

    const { rums, closeModal, show } = props;
    const { logoutUser, userDetails } = useContext(UserContext);

    // personal stats
    const numSignedRums = rums.filter(rum => "approver" in rum).length;
    const numSignedImmortals = rums.filter(rum => "approver" in rum && rum.is_immortal).length;
    const numAwaitingApproval = rums.filter(rum => !rum.approved_on && rum.requested_on).length;
    const untriedAvailable = rums.filter(rum => !rum.approved_on && !rum.requested_on && rum.is_available).length;
    const triedUnavailable = rums.filter(rum => rum.approved_on && !rum.is_available).length;

    // global stats
    const availableRum = rums.filter(rum => rum.is_available).length;
    const totalImmortals = rums.filter(rum => rum.is_immortal).length;
    const availableImmortals = rums.filter(rum => rum.is_immortal && rum.is_available).length;
    
    return (
        <>
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{userDetails.firstName} {userDetails.lastName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Stack gap={1}>
                    <div className="p-2" style={{borderBottom: "1px solid grey"}}>
                        <label className="fw-bold">MY STATS</label>
                        <ProfileDataRow label={"Level"} value={userDetails.rumbustionLevel} />
                        <ProfileDataRow label={"Rums"} value={numSignedRums}/>
                        <ProfileDataRow label={"Immortals"} value={numSignedImmortals} />
                        <ProfileDataRow label={"Awaiting Approval"} value={numAwaitingApproval} />
                        <ProfileDataRow label={"Untried and available"} value={untriedAvailable} />
                        <ProfileDataRow label={"Tried and unavailable"} value={triedUnavailable} />
                    </div>
                    <div className="p-2" style={{borderBottom: "1px solid grey"}}>
                        <label className="fw-bold">OVERALL STATS</label>
                        <ProfileDataRow label={"Total Items"} value={rums.length} />
                        <ProfileDataRow label={"Available Items"} value={availableRum} />
                        <ProfileDataRow label={"Total Immortals"} value={totalImmortals} />
                        <ProfileDataRow label={"Available Immortals"} value={availableImmortals} />
                    </div>
                    <div className="p-2" style={{borderBottom: "1px solid grey"}}>
                        <label className="fw-bold">INDEPENDENT BOTTLERS</label>
                        <IndependentBottlerRow label={"Blackadder"} rums={rums} needed={15} filter={(rum) => rum.bevager_name.toLowerCase().includes("blackadder") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Boutique-y"} rums={rums} needed={18} filter={(rum) => rum.bevager_name.toLowerCase().includes("boutique") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Bristol Classic/Bristol Spirits"} rums={rums} needed={17} filter={(rum) => rum.bevager_name.toLowerCase().includes("bristol") && !rum.bevager_name.toLowerCase().includes("avery") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Cadenhead"} rums={rums} needed={14} filter={(rum) => rum.bevager_name.toLowerCase().includes("cadenhead") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Colours of Rum"} rums={rums} needed={14} filter={(rum) => rum.bevager_name.toLowerCase().includes("colours of rum") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Duncan Taylor"} rums={rums} needed={8} filter={(rum) => rum.bevager_name.toLowerCase().includes("duncan") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Hamilton"} rums={rums} needed={15} filter={(rum) => rum.bevager_name.toLowerCase().includes("hamilton") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Holmes Cay"} rums={rums} needed={24} filter={(rum) => rum.bevager_name.toLowerCase().includes("holmes cay") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Plantation"} rums={rums} needed={16} filter={(rum) => (rum.bevager_name.toLowerCase().includes("plantation") || rum.bevager_name.toLowerCase().includes("planteray")) && !rum.bevager_name.toLowerCase().includes("grove") && !rum.bevager_name.toLowerCase().includes("myer") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Royal Cane"} rums={rums} needed={13} filter={(rum) => rum.bevager_name.toLowerCase().includes("royal cane") && "approver" in rum}/>
                        <IndependentBottlerRow label={"S.B.S"} rums={rums} needed={15} filter={(rum) => rum.bevager_name.includes("SBS") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Samaroli"} rums={rums} needed={22} filter={(rum) => rum.bevager_name.toLowerCase().includes("samaroli") && "approver" in rum}/>
                        <IndependentBottlerRow label={"Velier"} rums={rums} needed={42} filter={(rum) => (rum.bevager_name.toLowerCase().includes("velier") || rum.bevager_name.toLowerCase().includes("lm&v")) && "approver" in rum}/>
                    </div>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={logoutUser}>
                    Logout
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}

export default UserProfileModal;
